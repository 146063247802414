<template>
  <div>
    <slot name="header" />
    <div class="pb-12">
      <div
        class="container mx-auto pb-12 pt-12 max-w-md"
        v-if="codeGenerated">
        <div class="grid grid-flow-row auto-rows-max text-center">
          <span>{{ data.receiptText }}</span>
          <span class="font-semibold bg-white p-4 rounded-sm text-center text-3xl mt-4">
            {{ referenceCode }}
          </span>
          <span class="mt-4 text-sm text-gray-800">
            {{ data.expirationText }}
          </span>
          <div class="mt-10">
            <div>
              {{ data.reminderText }}
            </div>
            <div
              v-if="notified"
              class="mt-10 text-center">
              <success-message>
                {{ data.notifiedText }} {{ formData.phone }}
              </success-message>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-center mt-12 mb-4 text-xl"
        v-if="data.manualCheckout">
        <div v-if="checkedOut">
          Takk. Svarskjemaet er nå registrert.
        </div>
        <div v-else>
          <default-button
            primary
            large
            :loading="submitting"
            @click.prevent.native="manualSubmit">
            {{ data.manualCheckoutButtonText }}
          </default-button>
        </div>
      </div>
    </div>
    <slot
      :is-valid="referenceCode != null"
      :is-hidden="data.hasCheckout" />
  </div>
</template>

<script>
import axios from "axios"
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    setFormData: {
      type: Function,
      default() {
        return () => { }
      }
    },
    submit: {
      type: Function,
      default () {
        return () => {}
      }
    },
    submitting : {
      type: Boolean,
      default () {
        return false
      }
    }
  },

  data() {
    return {
      referenceId: null,
      referenceCode: null,
      codeGenerated: false,
      checkedOut: false,
      notified: false,
      loading: false
    }
  },

  async created() {
    const response = await axios.post("/api/reference/generate", {})
    this.codeGenerated = true
    this.referenceCode = response.data.reference.code
    this.referenceId = response.data.reference.id
    this.data.model = this.referenceCode
    this.setFormData("referenceId", this.referenceId)
    if(this.hasPhone)
    {
      await axios.post("/api/reference/notify", {referenceId: this.referenceId, phoneVerificationId: this.formData.phoneVerificationId, message: this.data.notificationText})
      this.notified = true
    }

    if(this.data.hasCheckout && this.data.autoCheckout)
    {
      this.loading = true      
      this.submit(false)
      this.loading = false
      this.checkedOut = true
    }
  },

  computed: {
    hasPhone()
    {
      return this.formData.phoneVerificationId != null && this.formData.phone != null
    }
  },

  methods: {
    manualSubmit()
    {
      this.loading = true
      this.submit(false)
      this.checkedOut = true
      this.loading = false
    }
  }
}
</script>
